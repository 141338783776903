import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

import { NoStyleButton } from "@aviary/components/Button";

import * as styles from "./Header.styles";

interface Props
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  /**
   * Unique tab identifier used to match TabHeader with TabContent
   *
   * @required
   */
  tabId: string;
  /**
   * Current open tab id, used for styling and conditional opening
   *
   * @borrows This value is passed in from <Tabs>
   */
  openTabId?: string;
  /**
   * Overrides the default styling of the tab header for custom TabHeader trigger elements
   *
   * @default false
   */
  isCustomChild?: boolean;
  /**
   * DO NOT USE THIS PROP. IT IS INTERNAL ONLY
   * Callback for changing the targetTabId.
   *
   * @borrows This value is passed in from <Tabs>
   */
  // onTriggerClicked?: (targetTabId) => void;
  /**
   * Content to render in the header, see isCustomChild
   *
   * @default undefined
   */
  children?: ReactNode;
}

const Header = ({
  tabId,
  openTabId,
  isCustomChild,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onTriggerClicked,
  children,
  ...props
}: Props) => {
  const isOpen = tabId === openTabId;

  const tabStyles = [styles.tabHeader, isOpen && styles.tabSelected];

  if (isCustomChild) {
    return (
      <NoStyleButton
        role="tab"
        aria-selected={isOpen ? "true" : "false"}
        id={`tab-${tabId}`}
        aria-controls={`panel-${tabId}`}
        onClick={() => onTriggerClicked(tabId)}
        {...props}
      >
        {children}
      </NoStyleButton>
    );
  }

  return (
    <NoStyleButton
      css={tabStyles}
      role="tab"
      aria-selected={isOpen ? "true" : "false"}
      aria-controls={`panel-${tabId}`}
      onClick={() => onTriggerClicked(tabId)}
      {...props}
    >
      {children}
    </NoStyleButton>
  );
};

Header.displayName = "Tabs.Header";

export { Header };
