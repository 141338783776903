import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { Flipper } from "@shared/types/graphqlGenerated";

const FlipperUpdate_Shared_Mutation = gql`
  mutation FlipperUpdate_Shared_Mutation($input: FlipperUpdateInput!) {
    flipperUpdate(input: $input) {
      flipper {
        id
        name
        result
      }
    }
  }
`;

type FlipperFragment = Pick<Flipper, "id" | "name" | "result">;

interface FlipperUpdateData {
  flipperUpdate: {
    flipper: FlipperFragment;
  };
}

interface Variables {
  input: {
    enable: boolean;
    flipper: String;
  };
}

const useFlipperUpdateMutation = (options?: MutationHookOptions<FlipperUpdateData, Variables>) =>
  useMutation<FlipperUpdateData, Variables>(FlipperUpdate_Shared_Mutation, options);

export type { FlipperUpdateData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useFlipperUpdateMutation, FlipperUpdate_Shared_Mutation };
