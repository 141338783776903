import type { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, ApolloLink } from "@apollo/client";

import { getCache } from "@shared/data/client/cache";

import { getUploadLink } from "./links/uploadLink";

/**
 * A basic Apollo client with bare minimum required to get things working
 * This does not include pagination logic, gql persistence or unauthenticated redirection logic
 *
 * DO NOT USE THIS! - Best to use a client specific to each SPA
 *
 * @param gqlPersistedQueriesFlipper - whether or not to enable persisted queries
 */
const getClient = (): ApolloClient<NormalizedCacheObject> => {
  const getLink = () => {
    const uploadLink = getUploadLink();

    return ApolloLink.from([uploadLink]);
  };

  return new ApolloClient({
    link: getLink(),
    cache: getCache(),
  });
};

export { getClient };
