import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { removeClickOutline } from "@styles/helpers";

export const tabHeader = (theme: Theme) => css`
  transition: all 0.3s ease-out;
  color: ${theme.system.textLabelEmphasis};
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  min-width: max-content;
  padding: 0 1rem;
  font-weight: ${typography.weightSemiBold};

  ${removeClickOutline};

  &:hover {
    background-color: ${theme.system.backgroundMuted};
    color: ${theme.system.textLabelEmphasis};
  }
  &:focus-visible {
    outline: none;
    border-color: ${theme.system.borderFocused};
  }
`;

export const tabSelected = (theme: Theme) => css`
  background-color: ${theme.light.backgroundBase};
  color: ${theme.light.textBase};
  ${removeClickOutline};

  &:hover {
    background-color: ${theme.light.backgroundBase};
    color: ${theme.light.textBase};
    ${removeClickOutline};
  }
`;
