import { css } from "@emotion/react";

export const content = css`
  margin-top: 2rem;
  padding: 0 0.5rem;
`;

export const persistContainer = css`
  margin-left: 2rem;
  margin-right: 2rem;
`;
