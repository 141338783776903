import { useExperiment } from "@shared/hooks/useExperiment/useExperiment";

import BearPat from "./ThePats/Bear Pat.development.jpg";
import HappyPat from "./ThePats/Happy Pat.development.png";
import ImJustSuitPat from "./ThePats/Im Just Suit Pat.development.png";
import MoustachePat from "./ThePats/Moustache Pat.development.png";
import PatCrush from "./ThePats/Pat Crush.development.jpg";
import PatImg from "./ThePats/Pat.development.png";
import PeteWentz from "./ThePats/Pete Wentz.development.png";
import ProfessionalPat from "./ThePats/Professional Pat.development.jpg";
import RockOnPat from "./ThePats/Rock on Pat.development.png";
import SadPat from "./ThePats/Sad Pat.development.png";
import SpideyPat from "./ThePats/Spidey Pat.development.jpg";
import SteakPat from "./ThePats/Steak Pat.development.jpg";

import * as styles from "./ExperimentTester.styles";

const random = Math.random();

const ThePats = {
  "Bear Pat": BearPat,
  "Happy Pat": HappyPat,
  "Moustache Pat": MoustachePat,
  "Pat Crush": PatCrush,
  Pat: PatImg,
  "Pete Wentz": PeteWentz,
  "Professional Pat": ProfessionalPat,
  "Sad Pat": SadPat,
  "Rock on Pat": RockOnPat,
  "I'm Just Suit Pat": ImJustSuitPat,
  "Spidey Pat": SpideyPat,
  "Steak Pat": SteakPat,
};

const Pat = () => {
  const pat = useExperiment({
    subjectId: `${random}`,
    experiment: "Which Pat is best?",
    groups: Object.keys(ThePats),
    owner: "Fullscript",
    shouldLog: false,
    authedExperiment: true,
    unauthedExperiment: true,
  });

  return <img role="img" css={styles.pat} src={ThePats[pat]} />;
};

export { Pat };
