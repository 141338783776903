import { faArrowDown } from "@fortawesome/pro-regular-svg-icons";

import { IconButton } from "@aviary";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./Footer.styles";

const HideButton = () => {
  const handleClick = () => {
    const event = new CustomEvent("toggle-experiment-tester");
    window.dispatchEvent(event);
  };

  return (
    <IconButton
      isFullWidth
      aria-label="Hide"
      intention="system"
      onClick={handleClick}
      css={styles.button}
    >
      <FontAwesomeIcon icon={faArrowDown} />
    </IconButton>
  );
};

export { HideButton };
