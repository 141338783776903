import { useEffect } from "react";

import { useLatestCallback } from "../useLatestCallback/useLatestCallback";

type Func = EventListener;

interface Props<T> {
  listener: T;
  eventName: string;
}

const useEventListener = <T extends Func>({ listener, eventName }: Props<T>) => {
  const latestListener = useLatestCallback(listener);

  useEffect(() => {
    window.addEventListener(eventName, latestListener);

    return () => window.removeEventListener(eventName, latestListener);
  }, [eventName, latestListener]);
};

export { useEventListener };
