import { InMemoryCache } from "@apollo/client";

import { possibleTypes } from "@shared/data/client/possibleTypes";

/**
 * This is a baseline cache required to get ApolloClient running.
 * You probably want something more robust for your SPA, for example is doesn't contain any pagination.
 */
const getCache = () =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          viewer: {
            merge: true,
          },
        },
      },
      Mutation: {
        fields: {
          viewer: {
            merge: true,
          },
        },
      },
      Viewer: {
        // This means the singleton Viewer's identity does not depend on any of its
        // fields (except for __typename), making the ID effectively constant.
        keyFields: [],
      },
    },
  });

export { getCache };
