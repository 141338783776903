import { css, type Theme } from "@emotion/react";

import { utilities } from "@styles";

export const wrapper = css`
  position: relative;
`;

export const inputStyles = (theme: Theme) => css`
  ${utilities.transition};
  background-color: ${theme.system.backgroundBase};
  border: 1px solid ${theme.system.borderBase};
  color: ${theme.input.textBase};
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;

  &::placeholder {
    ${utilities.transition};
    color: ${theme.input.textPlaceholder};
  }

  &:not([disabled]) {
    &:hover {
      border-color: ${theme.system.borderHover};
    }

    &:active,
    &:focus {
      outline: none;
      border-color: ${theme.system.borderFocused};

      &::placeholder {
        color: ${theme.input.textPlaceholder};
      }
    }
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${theme.disabled.inputLabelText};
    background-color: ${theme.disabled.inputBackground};
    border-color: ${theme.disabled.border};
  }
`;

export const fullwidth = css`
  width: 100%;
`;

export const iconBase = css`
  position: absolute;
  padding-top: 0.5rem;
  z-index: 2;
`;

export const iconSizing = {
  small: css`
    padding-top: 0.35rem;
  `,
  normal: css`
    padding-top: 0.5rem;
  `,
  large: css`
    padding-top: 1rem;
  `,
};

export const iconLocations = {
  start: css`
    left: 1rem;
  `,
  end: css`
    right: 1rem;
  `,
};

export const withIcon = {
  start: css`
    padding-left: 2.5rem;
  `,
  end: css`
    padding-right: 2.5rem;
  `,
};

export const inputSizes = {
  small: css`
    height: 2.25rem;
    padding: 1rem;
  `,
  normal: css`
    height: 2.5rem;
    padding: 1rem;
  `,
  large: css`
    height: 3.5rem;
    padding: 1rem;
  `,
};

export const closeBase = (theme: Theme) => css`
  position: absolute;
  right: 0.5rem;
  font-size: 1.25rem;

  path {
    fill: ${theme.input.textBase};
  }
`;
