import { setContext } from "@sentry/react";

type AddExperimentToSentryContextParams = { experimentName: string; group: string };

let sentryContextExperiments: Record<string, string> = {};

/**
 * Reset the Sentry context experiments variable to it's initial state
 */
const resetSentryContextExperiments = () => {
  sentryContextExperiments = {};
};

/**
 * Add an experiment by name and group to the Sentry experiments context
 * This is used to identify all the experiments that a user is part of
 *
 * We then attach the list of experiments to Sentry errors for easier triaging
 */
const addExperimentToSentryContext = ({
  experimentName,
  group,
}: AddExperimentToSentryContextParams) => {
  sentryContextExperiments = { ...sentryContextExperiments, [experimentName]: group };
  setContext("experiments", { experiments: sentryContextExperiments });
};

export { addExperimentToSentryContext, resetSentryContextExperiments };
