import { css, type Theme } from "@emotion/react";

export const font = (theme: Theme) => css`
  color: ${theme.text.onBackground};

  & {
    > label {
      color: ${theme.text.onBackground};
    }
  }
`;
