import { css } from "@emotion/react";

export const container = css`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const button = css`
  border: none;
  width: 100%;
`;
