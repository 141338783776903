import { HideButton } from "./HideButton";

import * as styles from "./Footer.styles";

const Footer = () => {
  return (
    <div css={styles.container}>
      <HideButton />
    </div>
  );
};

export { Footer };
