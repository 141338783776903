import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const ExperimentCreate_Shared_Mutation = gql`
  mutation ExperimentCreate_Shared_Mutation($input: ExperimentCreateInput!) {
    experimentCreate(input: $input) {
      clientMutationId
    }
  }
`;

interface ExperimentCreateData {
  experimentMutation: {
    clientMutationId?: string;
  };
}

interface Variables {
  input: {
    experiment: string;
    owner: string;
    group: string;
    subjectId: string;
  };
}

const useExperimentCreateMutation = (
  options?: MutationHookOptions<ExperimentCreateData, Variables>
) => useMutation<ExperimentCreateData, Variables>(ExperimentCreate_Shared_Mutation, options);

export type { ExperimentCreateData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useExperimentCreateMutation, ExperimentCreate_Shared_Mutation };
