import type { DetailedHTMLProps, HtmlHTMLAttributes, ReactNode } from "react";

import { Spacer } from "@aviary/components/Spacer";

interface Props extends DetailedHTMLProps<HtmlHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * Unique tab identifier used to match TabHeader with TabContent
   *
   * @required
   */
  tabId: string;
  /**
   * Current open tab id, used for styling and conditional opening
   *
   * @borrows This value is passed in from <Tabs>
   */
  openTabId?: string;
  /**
   * Callback for changing the targetTabId
   *
   * @borrows This value is passed in from <Tabs>
   */
  onTriggerClicked?: (targetTabId) => void;
  /**
   * Tabbable content
   *
   * @default undefined
   */
  children?: ReactNode;
}

const Content = ({
  tabId,
  openTabId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTriggerClicked,
  children,
  ...props
}: Props) => {
  if (tabId !== openTabId) return null;

  return (
    <>
      <div id={`panel-${tabId}`} role="tabpanel" aria-labelledby={tabId} {...props}>
        {children}
      </div>
      <Spacer type="section" />
    </>
  );
};

Content.displayName = "Tabs.Content";

export { Content };
