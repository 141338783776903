import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const ExperimentStarter_Shared_Mutation = gql`
  mutation ExperimentStarter_Shared_Mutation($input: AnalyticsStartExperimentInput!) {
    analyticsStartExperiment(input: $input) {
      clientMutationId
    }
  }
`;

interface UnauthExperimentStarterData {
  analyticsStartExperiment: {
    clientMutationId: string;
  };
}

interface Variables {
  input: {
    experiment: string;
    experimentVariant: string;
  };
}

const useUnauthExperimentStarterMutation = (
  options?: MutationHookOptions<UnauthExperimentStarterData, Variables>
) =>
  useMutation<UnauthExperimentStarterData, Variables>(ExperimentStarter_Shared_Mutation, options);

export type { UnauthExperimentStarterData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useUnauthExperimentStarterMutation, ExperimentStarter_Shared_Mutation };
