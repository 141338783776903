import { useEffect } from "react";

const useKeyPressed = (key: string, onKeyDownEvent: (e: KeyboardEvent) => void) => {
  useEffect(() => {
    const handleKeyDownEvent = (e: KeyboardEvent) => {
      if (e.key === key) {
        onKeyDownEvent(e);
      }
    };

    document.addEventListener("keydown", handleKeyDownEvent, { capture: true });

    return () => {
      document.removeEventListener("keydown", handleKeyDownEvent, { capture: true });
    };
  }, [onKeyDownEvent]);
};

export { useKeyPressed };
