import { css, type Theme } from "@emotion/react";

import { animations, layers, timing, helpers } from "@styles";

const scrollShadow = (gradientAngle, theme: Theme) => css`
  opacity: 0;
  content: "";
  display: block;
  transition: opacity ${timing.defaultTiming} ${animations.easeOutCubic};
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 1rem;
  z-index: ${layers.zIndexLowForeground};

  background: linear-gradient(
    ${gradientAngle},
    ${helpers.hexToRgba(theme.system.backgroundMutedHover, 0.15)} 0%,
    ${helpers.hexToRgba(theme.system.backgroundMutedHover, 1)} 100%
  );
`;

export const wrapper = (theme: Theme) => css`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  background-color: ${theme.system.backgroundMutedHover};
  width: max-content;
  max-width: 100%;

  &:after {
    ${scrollShadow("90deg", theme)};
    right: 0;
  }

  &:before {
    ${scrollShadow("-90deg", theme)};
    left: 0;
  }
`;

export const tabHeaders = css`
  display: flex;
  padding: 0.25rem;
  overflow-x: auto;
  max-width: 100%;

  & > button:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  & > button {
    flex: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const showRightShadow = css`
  &:after {
    opacity: 1;
  }
`;

export const showLeftShadow = css`
  &:before {
    opacity: 1;
  }
`;

export const reducedMargin = css`
  margin-bottom: 0.5rem;
`;

export const fullwidth = css`
  width: 100%;
`;
